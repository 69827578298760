import React from "react"
import { Link } from "gatsby"
import AMap from "../components/A-map"
import Images from "../components/image"
import Layout from "../components/0-layout"
import Query from "../components/query"
import SEO from "../components/seo"
import "../scss/index.scss"

const IndexPage = () => {
  const data = Query()

  return (
    <Layout sitePath={"index"}>

      <SEO title={data.site.siteMetadata.description}/>

      <div id={"menu"}>
        <Link to={"/warehouse/"} id={"warehouse"}>
          <Images filename={"top/warehouse.jpg"} alt={data.site.siteMetadata.warehouse}/>
        </Link>

        <Link to={"/service/"} id={"service"}>
          <Images filename={"top/service.jpg"} alt={data.site.siteMetadata.service}/>
        </Link>
      </div>

      <p>&nbsp;</p>

      <h2 className={"title-orange"}>{data.site.siteMetadata.webSystem}</h2>

      <div className={"img"}>
        <Images filename={"warehouse/web-system.jpg"}
                alt={data.site.siteMetadata.webSystem}/>
      </div>

      <p>&nbsp;</p>

      <AMap/>

    </Layout>
  )
}

export default IndexPage
