import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Images from "./image"
import "../scss/a-map.scss"

/**
 * @property {object} allMapJson
 * @property {object} serviceArea
 * @property {object} underPreparationArea
 */

const AMap = () => {
  const data = useStaticQuery(graphql`
      query AMapQuery {
          allMapJson {
              edges {
                  node {
                      serviceArea
                      underPreparationArea
                  }
              }
          }
      }
  `)

  let serviceArea = []
  data.allMapJson.edges[0].node.serviceArea.forEach(i =>
    serviceArea.push(
      <li key={i}>{i}</li>,
    ),
  )

  let underPreparationArea = []
  data.allMapJson.edges[0].node.underPreparationArea.forEach(i =>
    underPreparationArea.push(
      <li key={i}>{i}</li>,
    ),
  )

  return (
    <>
      <h2 className={"title-orange"}>タイヤパークサービス提供エリア</h2>

      <p className={"area-map-p"}>
        ▶&nbsp;タイヤパークは全国展開へ向け、着実にサービスエリアを拡大しています。
      </p>

      <div id={"area-map"}>
        <article className={"area"} id={"service-area"}>
          <h3>提供エリア</h3>

          <ul className={"flex-start-wrap"}>
            {serviceArea}
          </ul>
        </article>

        <article>
          <Images filename={"service/map.jpg"} alt={"タイヤパークサービス提供エリア"}/>
        </article>

        <article className={"area"} id={"under-preparation-area"}>
          <h3>準備中エリア</h3>

          <ul className={"flex-start-wrap"}>
            {underPreparationArea}
          </ul>
        </article>
      </div>
    </>
  )
}

export default AMap
